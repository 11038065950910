<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">

          <h1>Уведомления</h1>
          <h2>Непрочитанных: {{getUnreadedCount()}}</h2>

          <div class="notifications-list">
            <div v-for="notif in notifications" :key="'fsdf'+notif.id" class="notifications-list-item">
              <div v-if="!notif.isReaded" class="notifications-list-item-unread"></div>
              <div v-else class="notifications-list-item-read"></div>
              <h2>{{ notif.title }}
              </h2>
              <p>{{ notif.text }}</p>
              <div class="notifications-list-item-date">{{ notif.localDateTime | dateTimeFormat }}</div>
              <a v-if="!notif.isReaded" href="#" @click.stop="setReaded(notif.id)">Отметить прочитанным</a>
            </div>
          </div>


          <router-link to="/config/notify" v-if="($user.role==='ROLE_ADMIN' || $user.role==='ROLE_VNII') && $vcotUser !== true">Направить уведомления</router-link>


        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

export default {
  props: {},
  components: {},
  data() {
    return {
      notifications: [],
      apiLoaded: false,
      errorText: null
    };
  },
  filters: {
    dateTimeFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {
        timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    async loadMessages() {
      let req = await this.$getApi("/notifs/getUserNotifications",
          {userName: this.$user.username})
      if (req.ok) {
        this.notifications = req.payload
        if(this.notifications == null)
          this.notifications = []
        this.notifications.sort((a, b) => (a["localDateTime"] < b["localDateTime"]) ? 1 : -1)
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    getUnreadedCount() {
      return this.notifications.filter((it) => !it.isReaded).length;
    },

    async setReaded(notificationId) {
      let req = await this.$getApi("/notifs/setReaded",
          {notificationId: notificationId})
      if (req.ok) {
        await this.loadMessages();
        this.$forceUpdate();
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    }
  },
  async beforeMount() {
    await this.loadMessages()
    this.apiLoaded = true
  }
};
</script>
